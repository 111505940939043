import React, { useState, useEffect, useContext } from "react";
import { Form, FormControl } from "react-bootstrap";
import HtmlForm from "./html/HtmlForm.jsx";
import RatingForm from "./rating/RatingForm.jsx";
import UserInput from "./userinput/UserInput.jsx";
import MainOptions from "./main-options/MainOptions.jsx";
import TriggerSelect from "./trigger-select/TriggerSelect.jsx";
import { stepFormKeys } from "./step-form-keys";
import "./bot-step-form-styles/main-form.css";
import StyleContext from "../../../styling/styleContext";

import { API } from "aws-amplify";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../../components/CompanySelector.jsx";

const NewBotStepForm = ({ type, botStep, formType, setBotStep }) => {
  const style = useContext(StyleContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setBotStep({ ...botStep, [name]: value });
  };

  const [availableStepsByID, setAvailableStepsByID] = useState({});
  const [trigger, setTrigger] = useState();
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    setBotStep({ displayName: "", ...stepFormKeys[formType] });
    setTrigger("");
  }, [formType, setBotStep]);

  useEffect(() => {
    async function fetchAvailableSteps() {
      const response = await API.get("dashboardapi", "/v1/bot-questions", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId
        }
      });
      const data = await response;
      const objectOfIDs = {};
      data.questions[type][0].probing_questions.forEach(
        (step) => (objectOfIDs[step.id] = step)
      );
      data.questions[type][0].steps.forEach(
        (step) => (objectOfIDs[step.id] = step)
      );
      setAvailableStepsByID(objectOfIDs);
    }
    fetchAvailableSteps();
  }, [type, activeCompanySnapshot?.companyId]);

  return (
    <Form>
      <div className="step-input">
        <h6 style={{ ...style.styleSizing, color: style.colors.blue }}>
          Name Your New Step:
        </h6>
        <FormControl
          className="modal-input"
          name="displayName"
          value={botStep.displayName}
          onChange={handleChange}
          type="text"
          placeholder="new bot step"
        />
      </div>
      {"trigger" in botStep && (
        <TriggerSelect
          onChange={(e) => {
            setTrigger(e.target.value);
            if (e.target.id !== "custom") {
              setBotStep({ ...botStep, trigger: e.target.value });
            }
          }}
          trigger={trigger}
          availableStepsByID={availableStepsByID}
        />
      )}
      {(() => {
        switch (formType) {
          case "message":
            return (
              <div className="step-input">
                <Form.Label>
                  <h6
                    style={{ ...style.styleSizing, color: style.colors.blue }}
                  >
                    Input The Message Your Bot Will Say:
                  </h6>
                </Form.Label>
                <FormControl
                  className="modal-input"
                  name="message"
                  value={botStep.message}
                  onChange={handleChange}
                  type="text"
                  placeholder="Hello!"
                />
              </div>
            );
          case "simple-options":
          case "image-options":
            return (
              <MainOptions
                botStep={botStep}
                availableStepsByID={availableStepsByID}
                optionsType={formType}
                setBotStep={setBotStep}
              />
            );
          case "html":
            return <HtmlForm botStep={botStep} setBotStep={setBotStep} />;
          case "rating":
            return <RatingForm botStep={botStep} setBotStep={setBotStep} />;
          case "userinput":
            return <UserInput botStep={botStep} setBotStep={setBotStep} />;
          default:
            return <div></div>;
        }
      })()}
    </Form>
  );
};

export default NewBotStepForm;
