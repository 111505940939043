// quotes button const buttonStyle = {
//   borderRadius: 10,
//   fontFamily: "'Proxima Nova'",
//   color: "black",
//   width: 135,
//   letterSpacing: 1,
//   fontSize: 18,
//   marginRight: 10

// programsDiv
// padding: 5px;
//     color: black;
//     border-radius: 10px;
//     margin-left: 10px;

// text area
// box-shadow: rgb(221 221 221) 2px 2px 2px 0px inset;
//     height: 197px;
//     margin: 10px;
//     overflow: auto;
//     padding: 10px;
//     width: 300px;
//     font-family: tahoma;
//     font-size: 13px;
//     border: 1px solid rgb(170, 170, 170);
//     border-radius: 10px;

// //     background: whitesmoke;
// padding: 30px;
// border-radius: 25px;
// margin-top: 50px;
// max-width: 1280px;

const yellow = "rgb(252, 220, 105)";
const teal = "rgb(34, 212, 174)";
// const green = "rgb(0, 152, 121)";
const green = "rgb(53, 180, 89)";
const white = "#f5f5f5";
const otherGreen = "rgb(53, 180, 89)";

const Styles = {
  styleSizing: {
    fontSize: "18px",
    margin: "2px",
    fontFamily: "VAG-Rounded-Std"
  },
  colors: {
    yellow: "rgb(252, 220, 105)",
    teal: "rgb(34, 212, 174)",
    green: "rgb(53, 180, 89)",
    // green: "rgb(0, 152, 121)",
    white: "#f5f5f5",
    blue: "#3f51b5"
  },
  foo: (inp) => ({
    color: inp
  }),
  buttonStyle: (inp) => ({
    background: inp || teal,
    borderRadius: "10px",
    fontFamily: "VAG-Rounded-Std",
    color: "whitesmoke",
    width: "135px",
    letterSpacing: "1px",
    fontSize: "18px",
    marginRight: "10px",
    height: "60px",
    fontWeight: "bold",
    border: `1px solid ${green}`
  }),
  programsDiv: {
    padding: "5px",
    color: "black",
    borderRadius: "10px",
    marginLeft: "10px"
  },
  textArea: {
    width: "50%",
    overflow: "auto",
    padding: "15px",
    fontFamily: "tahoma",
    fontSize: "13px",
    border: "1px solid rgb(170, 170, 170)",
    borderRadius: "10px",
    boxShadow: "10px 10px 5px grey"
  },
  submitButton: (inp) => ({
    background: inp || otherGreen,
    borderRadius: "5px",
    border: `1px solid ${green}`,
    fontFamily: "VAG-Rounded-Std",
    color: "whitesmoke",
    width: "135px",
    letterSpacing: "2px",
    margin: "2px"
  }),
  editButton: {
    background: teal,
    borderRadius: "5px",
    border: `1px solid ${green}`,
    fontFamily: "VAG-Rounded-Std",
    color: "black",
    width: "100px",
    height: "30px",
    letterSpacing: "2px",
    margin: "2px"
  },
  deleteButton: {
    background: "red",
    borderRadius: "5px",
    // border: `1px solid ${green}`,
    fontFamily: "VAG-Rounded-Std",
    color: "whitesmoke",
    width: "135px",
    letterSpacing: "2px",
    margin: "2px"
  },
  tableButton: {},
  // dropDown: {
  //   // paddingRight: "20px",
  //   // fontFamily: "Proxima Nova",
  //   // color: "rgb(178, 178, 178)"
  //   background: "white",
  //   color: "black",
  //   borderRadius: "10px",
  //   marginLeft: "10px",
  //   height: "35px",
  //   width: "240px",
  //   padding: "8px"
  // },
  dropDown: {
    marginLeft: "10px",
    height: "35px",
    width: "240px",
    padding: "8px",
    boxShadow: "1px 1px 2px grey",
    WebkitAppearance: "button",
    WebkitUserSelect: "none",
    WebkitPaddingEnd: "20px",
    WebkitPaddingStart: "2px",
    // backgroundColor: "#F07575",
    // backgroundImage:
    //   "url(../images/select-arrow.png), -webkit-linear-gradient(top, whitesmoke, #F4F4F4)",
    backgroundPosition: "center right",
    backgroundRepeat: " no - repeat",
    border: "1px solid #AAA",
    borderTopColor: "rgb(170, 170, 170)",
    // borderRadius: "2px",
    // boxShadow: "0px 1px 3px rgb(0 0 0 / 10%)",
    color: "#555",
    fontSize: "inherit",
    overflow: "hidden 2px",
    paddingTop: "2px",
    paddingBottom: "2px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    borderRadius: "10px"
  },
  // Button: (inp) => ({
  //   color: inp
  // }),
  // Button: {
  //   color: "red",
  //   fontStyle: "italic"
  // },
  Paragraph: {
    lineHeight: "20px",
    background: "blue",
    color: "brown"
  },
  Div: {
    background: "pink",
    width: "500px"
  }
};

export default Styles;
