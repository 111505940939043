import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Button,
  Dropdown,
  Spinner,
  Form,
  InputGroup
} from "react-bootstrap";
import NewBotStepForm from "./bot-step-forms/NewBotStepForm.jsx";
import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import "./bot-step-styles/new-bot-step-modal.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RemoveButton } from "./remove-button/RemoveButton";

import S3ImageUploader from "../branding/image-uploader/S3ImageUploader.jsx";
import StyleContext from "../../styling/styleContext";
import { Conditionals } from "./conditionals/Conditionals";

import ImageOptions from "../bot-step/bot-step-forms/image-options/ImageOptions.jsx";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const CreateNewBotStepModal = ({
  updateBotQuestions = () => {},
  type,
  toggle,
  open,
  botId,
  step = {},
  addOption,
  specialStepChange,
  handleStepChange,
  extended,
  setStep,
  saveStep,
  defaultOption = "message"
}) => {
  const style = useContext(StyleContext);
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [loading, setLoading] = useState(false);
  const [botStep, setBotStep] = useState(step);
  const [originalBotStep, setOriginalBotStep] = useState(step);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [newOption, setNewOption] = useState();
  const [newImage, setNewImage] = useState();
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const handleAddOption = () => {
    setNewOption({
      value: "",
      trigger: ""
    });
    // []
  };

  const handleAddImages = () => {
    setNewImage({});
  };

  const handleNewImageChange = (event) => {
    const { name, value } = event.target;
    setNewImage({
      ...newImage,
      [name]: value
    });
  };

  const handleNewOptionChange = (event) => {
    const { name, value } = event.target;
    setNewOption({
      ...newOption,
      [name]: value
    });
  };

  const saveNewOption = (event) => {
    event.preventDefault();
    addOption(newOption);
    setNewOption(null);
  };

  const saveNewImage = async (event) => {
    event.preventDefault();
    const newOption = {
      image: newImage.image,
      label: newImage.text,
      value: newImage.value
    };
    // addOption(newOption);

    const {
      image: { file, contentType },
      value,
      text: label
    } = newImage;
    const ext = contentType.split("/")[1];
    await upload(step.length, label, value, file, contentType, ext);

    console.log({ uploadedImages });

    setStep([
      ...step,
      {
        title: label,
        src: uploadedImages[step.length]
      }
    ]);

    /*???([
 ...step,
 {
 value,
 label,
 image: uploadedImages[step.length]
 }
 ]);*/

    setNewImage(null);
  };

  console.log({ step });

  const cancelNewOption = (event) => {
    event.preventDefault();
    setNewOption(null);
  };

  let isImage;
  try {
    const { title, src } = step[0];
    isImage = title && src;
    if (isImage && selectedOption !== "image-options") {
      // setSelectedOption("image-options");
    }
  } catch {}

  let options = [
    "message",
    "simple-options",
    "image-options",
    "html",
    "rating",
    "userinput"
  ];

  console.log({ step, type, extended });

  if (step.id === "pre-modal-entry") {
    options = options.splice(0, 1);
    defaultOption = options[0];
  }

  const checkStepFields = () => {
    return Object.values(botStep).some((value) => {
      return (value !== 0 && !value) || (Array.isArray(value) && !value.length);
    });
  };

  const createNewBotStep = async () => {
    setLoading(true);
    const newBotStep = { ...botStep };
    newBotStep.id = `${uuidv4()}`;
    newBotStep.displayName = `${selectedOption}: ${newBotStep.displayName}`;
    console.log({ newBotStep, API });
    try {
      const updatedQuestionsResponse = await API.post(
        "dashboardapi",
        "/v1/bot-questions",
        {
          headers: {
            "Company-Id": activeCompanySnapshot?.companyId
          },
          body: { payload: { id: botId, type, newBotStep } }
        }
      );

      if (updatedQuestionsResponse.questions) {
        toggle(false);
        toast(`Created ${newBotStep.displayName}`);
        updateBotQuestions(updatedQuestionsResponse.questions[type]);
      }
    } catch (e) {
      console.error(e);
      toast("There was a problem creating your new step!");
    }
    setLoading(false);
  };

  const upload = async (index, type, name, file, contentType, ext) => {
    console.log({
      type,
      name,
      file,
      contentType,
      ext
    });

    const { newImages } = await API.put("dashboardapi", "/v1/upload-images", {
      body: { images: [{ file, name, contentType }] }
    });
    const [image] = newImages;

    uploadedImages[index] = image;
    setUploadedImages(uploadedImages);

    console.log({ image });
    return true;
  };

  let tracker = 0;

  console.log({ uploadedImages });

  let list = (extended && step && Object.entries(step)) || [];
  console.log({ list });
  if (list.length) {
    list = [
      ...list.filter(([key, value]) => !Array.isArray(value)),
      ...list.filter(([key, value]) => Array.isArray(value))
    ];
  }

  return (
    <div>
      <ToastContainer />
      <Modal
        style={{ boxShadow: "1px 1px 1px 1px #6164c1" }}
        show={open}
        backdrop="static"
        onHide={() => toggle(false)}
      >
        <Modal.Header
          style={{
            display: "flex",
            alignItems: "center"
          }}
          toggle={() => toggle(false)}
        >
          <>
            {!extended ? (
              <h2
                style={{
                  ...style.styleSizing,
                  color: style.colors.blue,
                  margin: 0,
                  fontSize: 22,
                  fontWeight: 500
                }}
              >{`Create New ${selectedOption
                .charAt(0)
                .toUpperCase()}${selectedOption.slice(1)}`}</h2>
            ) : (
              <h2
                style={{
                  ...style.styleSizing,
                  color: style.colors.blue,
                  fontSize: 22,
                  fontWeight: 500
                }}
              >
                {`Edit ${isImage ? "image-options" : selectedOption}`}{" "}
              </h2>
            )}
            {!extended && (
              <Dropdown>
                <Dropdown.Toggle
                  caret
                  style={{
                    ...style.styleSizing,
                    color: style.colors.whitesmoke,
                    margin: 0
                  }}
                >
                  Step Type
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {options.map((option, index) => (
                    <Dropdown.Item
                      key={`option-${index}`}
                      onClick={() => setSelectedOption(option)}
                    >
                      {option}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        </Modal.Header>
        <Modal.Body>
          {loading && (
            <div className="create-step-loader">
              <Spinner
                className="loader-spinner"
                animation="border"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}

          {!extended && (
            <div style={{ opacity: loading ? "0.1" : "1.0" }}>
              <NewBotStepForm
                setBotStep={setBotStep}
                type={type}
                botStep={botStep}
                formType={selectedOption}
              />
            </div>
          )}

          {console.log("this is step", step)}

          {list.map(([key, value], index) => {
            console.log({ key, value });
            // rendering image fields

            if (isImage) {
              const { title, src } = value;

              return (
                <>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid whitesmoke"
                    }}
                    className="mt-3"
                  >
                    {/* <Form.Label>Edit Title</Form.Label> */}
                    <Form.Label
                      style={{
                        ...style.styleSizing,
                        color: style.colors.blue,
                        fontSize: 20
                      }}
                    >{`Edit Title ${index + 1}`}</Form.Label>
                    <Form.Control
                      required
                      style={{
                        ...style.styleSizing,
                        color: style.colors.green,
                        fontSize: 15
                      }}
                      defaultValue={title}
                      onChange={handleStepChange}
                      name={`${key}.title`}
                    />
                    <S3ImageUploader
                      aspect={1 / 1}
                      uploaded={upload.bind(null, index)}
                      image={uploadedImages[index] || src}
                      filename={title}
                      type={title}
                      circular={true}
                      allowableContentType="image/*"
                    />
                  </div>
                </>
              );
            }

            // if (Array.isArray(value)) {
            // return (
            // <>
            // {value.map(({ label }, index) => (
            // <div key={index}>{label}</div>
            // ))}
            // </>
            // );
            // }

            // rendering message defaults optionType setVariable
            if (!Array.isArray(value)) {
              return (
                <div className="mt-3">
                  <Form.Label
                    style={{
                      ...style.styleSizing,
                      color: style.colors.blue
                    }}
                  >
                    {key[0].toUpperCase() + key.slice(1)}
                  </Form.Label>
                  <Form.Control
                    style={{
                      ...style.styleSizing,
                      color: style.colors.green,
                      fontSize: 14
                    }}
                    defaultValue={value}
                    onChange={handleStepChange}
                    name={key}
                    readOnly={key === "id" || key === "trigger"}
                    disabled={key === "id" || key === "trigger"}
                  />
                </div>
              );
            }

            // simple options
            return value.map((itemValue, index) => {
              const remove = () => {
                value.splice(index, 1);
                saveStep();
              };

              if (key == "conditionals") {
                return null;

                // const props = {
                // index,
                // key,
                // itemValue,
                // remove,
                // specialStepChange
                // };

                // return <Conditionals {...props} />;
              }

              // RENDERING SIMPLE OPTIONS
              else if (step.conditionals || Array.isArray(value)) {
                const triggerIndex = step.conditionals
                  ? step.conditionals.findIndex(
                      ({ matches }) => matches === itemValue.value
                    )
                  : null;
                const trigger = step.conditionals
                  ? step.conditionals[triggerIndex]
                  : null;
                return (
                  <div
                    style={{
                      padding: 10,
                      border: "1px solid whitesmoke",
                      marginBottom: 10,
                      marginTop: 20
                    }}
                  >
                    {index == 0 && (
                      <>
                        <h3
                          style={{
                            ...style.styleSizing,
                            color: style.colors.blue,
                            margin: 0,
                            fontSize: 24
                          }}
                          className="mt-4"
                        >
                          Edit Options{" "}
                        </h3>
                        {!newOption && (
                          <button
                            style={{
                              ...style.styleSizing,
                              ...style.submitButton(style.colors.green),
                              marginTop: 20
                            }}
                            onClick={handleAddOption}
                          >
                            Add Option
                          </button>
                        )}
                      </>
                    )}
                    <div className="mt-3">
                      <Form.Label
                        style={{
                          ...style.styleSizing,
                          color: style.colors.blue,
                          margin: 0,
                          fontSize: 20
                        }}
                      >
                        {key[0].toUpperCase() +
                          key.slice(1, key.length - 1) +
                          " " +
                          (index + 1)}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          style={{
                            ...style.styleSizing,
                            color: style.colors.green,
                            margin: 0,
                            fontSize: 14
                          }}
                          defaultValue={itemValue.value}
                          onChange={(e) => {
                            specialStepChange(e, index);
                          }}
                          name={key}
                          mr={3}
                        />
                        <RemoveButton onClick={remove} />
                      </InputGroup>
                      {trigger && (
                        <Conditionals
                          {...{
                            index: triggerIndex,
                            key: "conditionals",
                            itemValue: trigger,
                            remove,
                            specialStepChange
                          }}
                        />
                      )}
                    </div>
                  </div>
                );
              }
            });
          })}

          {/* // if (Array.isArray(value)) {
 // return (
 // <>
 // {value.map(({ label }, index) => (
 // <div key={index}>{label}</div>
 // ))}
 // </>
 // );
 // } */}

          {extended && step && isImage && (
            <button
              style={{
                ...style.styleSizing,
                ...style.submitButton(style.colors.green)
              }}
              onClick={handleAddImages}
            >
              Add Images
            </button>
          )}
          {/* add new options w/conditionals */}
          {newOption && (
            <form>
              <h4
                style={{
                  ...style.styleSizing,
                  color: style.colors.blue,
                  fontSize: 22,
                  marginTop: 30,
                  marginBottom: 14
                }}
              >
                Create New Options
              </h4>
              {
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {/* new option value */}
                  <Form.Label
                    style={{ ...style.styleSizing, color: style.colors.blue }}
                  >
                    Value
                  </Form.Label>
                  <input
                    className="add-new-option-input"
                    placeholder="Add New Option"
                    style={{
                      ...style.styleSizing,
                      marginLeft: 10,
                      width: 240,
                      borderRadius: 9,
                      height: 35,
                      fontSize: 14,
                      color: style.colors.green
                    }}
                    value={newOption.value}
                    name="value"
                    onChange={handleNewOptionChange}
                  />

                  {step.conditionals && (
                    <>
                      <Form.Label
                        style={{
                          ...style.styleSizing,
                          color: style.colors.blue
                        }}
                      >
                        Trigger
                      </Form.Label>
                      {/* rendering triggers into a drop down */}
                      <select
                        style={{
                          ...style.styleSizing,
                          ...style.dropDown,
                          color: style.colors.green,
                          padding: 5
                        }}
                        value={newOption.trigger}
                        name="trigger"
                        onChange={handleNewOptionChange}
                      >
                        {[
                          ...new Set([
                            ...step.conditionals.map(({ trigger }) => trigger)
                          ])
                        ].map((trigger) => (
                          <option key={trigger}>{trigger}</option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              }

              <div style={{ marginTop: 20 }}>
                <button
                  style={{
                    ...style.styleSizing,
                    ...style.submitButton(style.colors.green)
                  }}
                  onClick={saveNewOption}
                >
                  Submit
                </button>
                <button
                  style={{
                    ...style.styleSizing,
                    ...style.submitButton("red")
                  }}
                  onClick={cancelNewOption}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}

          {newImage && (
            <>
              <ImageOptions
                style={{ ...style.styleSizing }}
                newOption={newImage}
                handleOptionsChange={handleNewImageChange}
              />
              {newImage.image && (
                <button
                  style={{
                    // ...style.styleSizing,
                    ...style.submitButton(style.colors.green),
                    letterSpacing: 1
                  }}
                  onClick={saveNewImage}
                >
                  Save new image
                </button>
              )}
            </>
          )}

          {false && newImage && (
            <form action="">
              <h4>Add New Images</h4>
              <input
                className="add-new-option-input"
                placeholder="Add New Image Value"
                style={{
                  ...style.styleSizing,
                  marginLeft: 10,
                  width: 240,
                  borderRadius: 9,
                  height: 35,
                  fontSize: 14,
                  color: style.colors.green
                }}
                value={newImage.value}
                name="value"
                onChange={handleNewImageChange}
              />
              <input
                className="add-new-option-input"
                placeholder="Add New Image Value"
                style={{
                  ...style.styleSizing,
                  marginLeft: 10,
                  width: 240,
                  borderRadius: 9,
                  height: 35,
                  fontSize: 14,
                  color: style.colors.green
                }}
                value={newImage.label}
                name="value"
                onChange={handleNewImageChange}
              />
              <input
                onChange={handleNewImageChange}
                style={{ marginBottom: 10 }}
                type="file"
                name="image"
                id="image-option-field"
                accept={"image/*"}
                placeholder="new option image"
              />
              {newImage.image && (
                <img
                  className={"option-image"}
                  src={newImage.image.file}
                  alt="target"
                />
              )}
            </form>
          )}

          {console.log("this is type", selectedOption)}
          {/* {!newOption && selectedOption !== "image-options" && (
 <button
 style={{
 ...style.styleSizing,
 ...style.submitButton(style.colors.green),
 marginTop: 20
 }}
 onClick={handleAddOption}
 >
 Add Option
 </button>
 )} */}
        </Modal.Body>
        <Modal.Footer>
          {extended && (
            <Button
              className="btn-submit"
              color="primary"
              style={{
                ...style.styleSizing,
                color: style.colors.whitesmoke,
                margin: 0,
                borderColor: style.colors.green,
                background: style.colors.green
              }}
              onClick={() => {
                saveStep({
                  uploadedImages
                });

                // Reset state
                setUploadedImages([]);
              }}
            >
              Save Step
            </Button>
          )}
          {!extended && checkStepFields() && (
            <Button
              className="btn-submit"
              color="primary"
              style={{
                ...style.styleSizing,
                color: style.colors.whitesmoke,
                margin: 0,
                borderColor: style.colors.green,
                background: style.colors.green
              }}
              onClick={() => {
                createNewBotStep();
              }}
            >
              Create Step
            </Button>
          )}
          <Button
            color="secondary"
            // style={{
            // ...style.styleSizing,
            // color: style.colors.whitesmoke,
            // borderColor: style.colors.blue,
            // background: style.colors.blue
            // }}
            style={{
              ...style.styleSizing,
              ...style.submitButton(style.colors.blue),
              borderColor: style.colors.blue
            }}
            onClick={() => {
              setBotStep({});
              setSelectedOption("message");
              toggle(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateNewBotStepModal;
