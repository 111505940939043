import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  Fragment
} from "react";
import { FormControl, Form, Button, Card, Accordion } from "react-bootstrap";
import ImageOptions from "../image-options/ImageOptions.jsx";
import SimpleOptions from "../simple-options/SimpleOptions.jsx";
import TriggerSelect from "../trigger-select/TriggerSelect.jsx";
import "./main-options.css";
import StyleContext from "../../../../styling/styleContext";

const optionKeys = {
  "simple-options": { value: "", text: "", trigger: "", hideText: true },
  "image-options": { value: "", text: "", trigger: "", image: "" }
};

const MainOptions = ({
  availableStepsByID,
  botStep,
  setBotStep,
  optionsType
}) => {
  const style = useContext(StyleContext);
  const handleStepChange = (e) => {
    const { name, value } = e.target;
    setBotStep({
      ...botStep,
      [name]: value
    });
  };
  const [option, setOption] = useState({});
  const [optionTrigger, setOptionTrigger] = useState("");
  let options = botStep.options || [];

  const onSubmit = (e) => {
    e.preventDefault();
    setBotStep({
      ...botStep,
      options: [...(botStep.options || []), option]
    });
    setOption(optionKeys[optionsType]);
    setOptionTrigger("");
    const input = document.getElementById("image-option-field");
    if (input) {
      input.value = "";
    }
  };

  const handleOptionChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setOption({ ...option, [name]: value });
    },
    [option]
  );

  const [toggle, setToggle] = useState(false);

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setBotStep({
      ...botStep,
      options: newOptions
    });
  };

  useEffect(() => {
    setOption(optionKeys[optionsType]);
  }, [optionsType, setBotStep]);
  let addButtonDisabled = Object.values(option).some((value) => {
    if (value !== 0 && !value) {
      return true;
    }
    return false;
  });

  const submitButton = (
    <Button
      variant="outline-primary"
      className="add-option"
      onClick={onSubmit}
      style={{ float: "right" }}
      disabled={addButtonDisabled}
    >
      Add Option
    </Button>
  );
  return (
    <Fragment>
      {optionsType === "simple-options" && (
        <FormControl
          className="modal-input"
          onChange={handleStepChange}
          type="text"
          name="setVariable"
          placeholder="set-variable"
          value={botStep.setVariable}
        />
      )}
      <div className="current-options">
        <h5
          style={{
            ...style.styleSizing,
            color: style.colors.blue,
            fontWeight: 400
          }}
        >
          New Created Options:
        </h5>
        <ul>
          {options.length ? (
            options.map((option, index) => {
              return (
                <li key={`option-${index}`}>
                  <Card className="option-card">
                    <div>
                      {option.value}
                      <Button
                        onClick={() => removeOption(index)}
                        className="remove-option"
                        class="close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </Button>
                    </div>
                  </Card>
                </li>
              );
            })
          ) : (
            <p style={{ fontWeight: 500 }}>
              You have no created options. Create some with the form below.
            </p>
          )}
        </ul>
      </div>
      <hr />
      <div className="new-option-container">
        <div style={{ margin: 5 }}>
          <Accordion>
            <Card style={{ borderRadius: 25 }}>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <Form.Label>
                    <div
                      className="new-options-div"
                      style={{
                        ...style.styleSizing,
                        color: style.colors.blue,
                        fontWeight: 400,
                        marginLeft: 20
                      }}
                      onClick={() => setToggle(!toggle)}
                    >
                      {toggle ? "Close Options" : "Create New Options"}
                    </div>
                  </Form.Label>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div style={{ padding: 10 }}>
                    {(() => {
                      switch (optionsType) {
                        case "image-options":
                          return (
                            <ImageOptions
                              newOption={option}
                              handleOptionsChange={handleOptionChange}
                            />
                          );
                        case "simple-options":
                          return (
                            <SimpleOptions
                              newOption={option}
                              handleOptionsChange={handleOptionChange}
                            />
                          );
                        default:
                          return <div></div>;
                      }
                    })()}

                    <TriggerSelect
                      onChange={(e) => {
                        setOptionTrigger(e.target.value);
                        if (e.target.id !== "custom") {
                          setOption({ ...option, trigger: e.target.value });
                        }
                      }}
                      trigger={optionTrigger}
                      availableStepsByID={availableStepsByID}
                    />
                  </div>

                  {submitButton}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </Fragment>
  );
};

export default MainOptions;
