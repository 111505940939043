import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

export const StackedLineGraph = (props) => {
  const [series, setSeries] = useState([]);
  const key = Math.floor(Math.random() * 1) + "stacked_chart";

  const boxref = useRef();

  useState(() => {
    setSeries(props.series);

    const win = window.jQuery(`#${key}`);

    setTimeout(() => {
      window.jQuery.plot(
        window.jQuery(`#${key}`),
        props.series,

        {
          series: {
            points: {
              show: true
            },
            lines: {
              show: true,
              lineWidth: 0
            },
            splines: {
              show: true,
              lineWidth: 2
            }
          },
          grid: {
            borderWidth: 1,
            color: "rgba(0,0,0,0.2)"
          },
          yaxis: {
            color: "rgba(0,0,0,0.1)"
          },
          xaxis: {
            mode: "time",
            timeBase: "milliseconds",
            timeformat: "%b",
            color: "rgba(0,0,0,0.1)"
          },
          legend: {
            show: true,
            backgroundColor: "#fff",
            position: "ne",
            backgroundOpacity: 1,
            labelFormatter: (i) => `<span style="color:black;">${i}</span>`,
            noColumns: 1
          }
        }
      );
    }, 100);
  }, [props.series]);

  return props.series.length ? (
    <div
      key={key}
      className="overflow-hidden"
      style={{ marginBottom: 15, border: 0 }}
    >
      <div className="" style={{ backgroundColor: "#ffffff", border: 0 }}>
        <h4
          className=""
          style={{
            marginTop: 10,
            marginLeft: 10,
            color: "#4E7CFF",
            fontWeight: "bold"
          }}
        >
          {/* <!-- <span className="pull-right">200 Chats This week</span> --> */}
          <span>{props.title}</span>
        </h4>
        <div
          id={key}
          ref={boxref}
          className="chart dashboard-line labels-white"
          style={{ height: 300 }}
        ></div>
      </div>
    </div>
  ) : null;
};

StackedLineGraph.propTypes = {
  title: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired
};
