export const stepFormKeys = {
  message: {
    trigger: "",
    message: ""
  },
  "simple-options": {
    optionType: "default",
    setVariable: "",
    options: []
  },
  "image-options": {
    optionType: "image-text-option",
    options: []
  },
  html: {
    trigger: "",
    component: { type: "html", html: "" }
  },
  rating: {
    trigger: "",
    component: { type: "rating", placeholder: "" }
  },
  userinput: {
    trigger: "",
    component: { type: "userinput", placeholder: "" }
  }
};
