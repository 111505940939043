import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  Fragment
} from "react";
import EditProgram from "./EditProgram.jsx";
import { Spinner } from "react-bootstrap";
import { Button } from "react-bootstrap";
import ServiceTables from "./services-table";
import ProgramTable from "./program-table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API } from "aws-amplify";

import StyleContext from "../../styling/styleContext";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const RenderPage = ({ renderHelpButtons, openVersionControl }) => {
  const style = useContext(StyleContext);

  const [toggleEdit, setToggleEdit] = useState(false);

  const months = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  };

  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const fetchPrograms = useCallback(async () => {
    setLoading(true);
    const data = await API.get("dashboardapi", "/v1/programs", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId
      }
    });
    setPrograms(data.message.data.getCompanyVersioned.programs.items);
    setLoading(false);
  }, [setLoading, setPrograms, API, activeCompanySnapshot?.companyId]);

  useEffect(() => {
    renderHelpButtons("programs");
    fetchPrograms();
  }, [renderHelpButtons, activeCompanySnapshot?.companyId]);

  const emptyProgram = { id: "newId", startMonth: "1", endMonth: "12" };
  const [active, setActive] = useState(false);
  const [newProgram, setNewProgram] = useState(false);

  const startNewProgram = () => {
    setNewProgram(true);
  };

  const endNewProgram = () => {
    setNewProgram(false);
  };

  const handler = (index) => {
    setActive(index);
    renderHelpButtons(index);
  };

  const notify = (val) => toast(val);

  const saveComplete = useCallback(() => {
    setLoading(true);
    notify("Program Successfully Modified");
    setActive(false);
    setNewProgram(false);
    fetchPrograms();
  }, [notify, setActive, setNewProgram, fetchPrograms]);

  // ONLY RETURNED AFTER A CLICKs
  return (
    <>
      <ToastContainer />
      {loading ? (
        <div id="crm-spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div
          className="programs-tab-container"
          style={{ width: "100%", marginLeft: 18 }}
        >
          {active ? (
            <EditProgram
              programs={programs}
              program={programs[active - 1]}
              active={active}
              setActive={setActive}
              onSaveComplete={saveComplete}
            />
          ) : newProgram ? (
            <EditProgram
              programs={[emptyProgram]}
              program={emptyProgram}
              active={1}
              setActive={endNewProgram}
              onSaveComplete={saveComplete}
            />
          ) : (
            <Fragment>
              <h2 id="real-green-header" style={{ marginTop: "20px" }}>
                Program{" "}
                <Button
                  style={{
                    padding: "0px 10px",
                    textDecoration: "none",
                    fontSize: "20px"
                  }}
                  variant="link"
                  onClick={() => startNewProgram()}
                  className="icon-plus"
                />
              </h2>
              {programs.map((program, i) => (
                <ProgramTable
                  key={`program_table_key ${i}`}
                  index={i + 1}
                  handler={handler}
                  program={program}
                  openVersionControl={openVersionControl}
                  programDescription={program.programDescription}
                  programName={program.programName}
                  startMonth={months[program.programStartMonth]}
                  endMonth={months[program.programEndMonth]}
                />
              ))}
            </Fragment>
          )}

          {active &&
          programs[active - 1] &&
          !programs[active - 1].useRealGreenPricingEngine ? (
            <Fragment>
              {" "}
              <br />
              <hr />
              <ServiceTables
                openVersionControl={openVersionControl}
                programId={programs[active - 1].id}
              />
            </Fragment>
          ) : null}
        </div>
      )}
    </>
  );
};
export default RenderPage;
