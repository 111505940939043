import React, { useState, useEffect, useContext, Fragment } from "react";
import { Table, Button } from "reactstrap";
import { Spinner } from "react-bootstrap";
import "./services-table.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { API } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { StyleContext } from "../../styling/styleContext";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const ServiceTables = ({ programId, openVersionControl }) => {
  const style = useContext(StyleContext);
  const [cutOffDate, setCutOffDate] = useState({});

  const notify = (val) => toast(val);

  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(true);
  const [setter, setSetter] = useState(false);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  const handleDateChange = (date, index) => {
    setCutOffDate({ id: index, date: date });
  };

  const submitData = async (data) => {
    try {
      const response = await API.post(
        "dashboardapi",
        `/v1/services/${programId}`,
        {
          headers: {
            "Company-Id": activeCompanySnapshot?.companyId
          },
          body: data
        }
      );

      if (response.message) {
        const updatedServices = services.map((service) => {
          if (service.id === data.id) {
            return data;
          } else {
            return service;
          }
        });
        setServices(updatedServices);
        setSetter(true);
        notify("Cut-Off Date Successfully Modified");
      } else {
        throw new Error("Updating Service Failed!");
      }
    } catch (error) {
      console.log("error: ", error);
      notify("Failed to Update Cut-Off Date");
    }
  };

  useEffect(() => {
    async function fetchServices() {
      const data = await API.get("dashboardapi", `/v1/services/${programId}`, {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId
        }
      });
      setServices(data.message);
      setSetter(false);
      // setLoadingServices(false);
    }
    if (programId) {
      fetchServices();
    }
  }, [programId, activeCompanySnapshot?.companyId]);

  const servicesFont = {
    // fontFamily: "Montserrat",
    // fontSize: "15px",
    fontSize: "1rem",
    margin: "2px",
    fontFamily: "VAG-Rounded-Std"
  };

  return (
    <>
      <h2
        style={{
          ...style.styleSizing,
          color: style.colors.blue,
          fontSize: "1.5em",
          fontWeight: 500
        }}
        id="services"
        className="context real-green-header"
      >
        Services
      </h2>
      <Table className="content-table" striped bordered hover>
        <thead style={{ background: "rgb(53, 180, 89)" }}>
          <tr style={{ background: style.colors.green }}>
            <th style={servicesFont}>
              {" "}
              <i style={{ margin: 5 }} className="fa fa-list-ol"></i>
              Service Name
            </th>
            <th style={servicesFont}>
              {" "}
              <i style={{ margin: 5 }} className="fa fa-list-ol"></i>
              Round{" "}
            </th>
            <th style={servicesFont}>
              <i style={{ margin: 5 }} className="fa fa-calendar-check-o"></i>{" "}
              Current Cut-Off Date
            </th>
            <th style={servicesFont}>
              {/* // style={{ */}
              {/* //   borderRadius: 10,
            //   padding: "7px 5px 5px 10px",
            //   fontSize: "15px"
            // }} */}
              <i style={{ margin: 5 }} className="fa fa-calendar"></i>
              Change Cut-Off Date
            </th>
            <th style={servicesFont}>
              <i style={{ margin: 5 }} className="fa fa-bookmark"></i>
              Save/Delete Cut-Off Date
            </th>
            <th style={servicesFont}>Version Control</th>
          </tr>
        </thead>

        {/* <tbody>
    {services.sort((a,b) => a.roundNumber - b.roundNumber).map((service,index) => {
        const { id,serviceName, priceTableId,roundNumber } = service
        const dateObject = service.cutOffDate
        const momentObject = moment(dateObject)
        const cutOffDateString = momentObject.format('YYYY-MM-DD')
        
        let selectedCutOffDate = moment(service.cutOffDate).toDate()
        if(cutOffDate && cutOffDate.id === index){
            selectedCutOffDate = moment(cutOffDate.date).toDate()
        }
        

        return (
        <tr key={index}>
        <td> {serviceName}</td>
        <td>{roundNumber}</td>
        <td>{cutOffDateString}</td>
        <td><DatePicker onChange={date => handleDateChange(date,index)} value={selectedCutOffDate} selected={ selectedCutOffDate}/></td>
        <td> 
        <Button 
        type='submit' 
        onClick={() => submitData({ 
        id: id, 
        serviceName: serviceName,
        cutOffDate: moment(selectedCutOffDate).format('YYYY-MM-DD'),
        priceTableId: priceTableId,
        roundNumber: roundNumber,  
      })} style={{background: '#009879', }} variant="primary" type="submit">
        Submit
      </Button></td>
      </tr>
        )})}
    </tbody> */}

        <tbody>
          {services
            .sort((a, b) => a.roundNumber - b.roundNumber)
            .map((service, index) => {
              const { id, serviceName, priceTableId, roundNumber } = service;
              const dateObject = service.cutOffDate;
              const momentObject = service.cutOffDate
                ? moment(dateObject)
                : moment();
              const cutOffDateString = service.CutOffDate
                ? momentObject.format("YYYY-MM-DD")
                : "none";

              let selectedCutOffDate = momentObject.toDate();
              if (cutOffDate && cutOffDate.id === index) {
                selectedCutOffDate = moment(cutOffDate.date).toDate();
              }

              return (
                <tr key={index}>
                  <td
                    style={{
                      fontFamily: "Montserrat",
                      letterSpacing: "2px",
                      borderRadius: "10px",
                      fontSize: "15px",
                      marginLeft: 5
                    }}
                  >
                    {" "}
                    {serviceName}
                  </td>
                  <td
                    style={{
                      fontFamily: "Montserrat",
                      letterSpacing: "2px",
                      borderRadius: "10px",
                      fontSize: "15px",
                      marginLeft: 5
                    }}
                  >
                    {roundNumber}
                  </td>
                  <td
                    style={{
                      fontFamily: "Montserrat",
                      letterSpacing: "2px",
                      borderRadius: "10px",
                      fontSize: "15px",
                      marginLeft: 5
                    }}
                  >
                    {cutOffDateString}
                  </td>
                  <td
                    style={{
                      padding: "5px",
                      fontFamily: "Montserrat",
                      letterSpacing: "5px",
                      borderRadius: "10px",
                      textAlign: "center",
                      fontSize: "15px"
                    }}
                  >
                    <DatePicker
                      onChange={(date) => handleDateChange(date, index)}
                      value={selectedCutOffDate}
                      selected={selectedCutOffDate}
                    />
                  </td>
                  <td>
                    <Button
                      style={style.submitButton(style.colors.green)}
                      type="submit"
                      onClick={() =>
                        submitData({
                          id: id,
                          serviceName: serviceName,
                          cutOffDate:
                            moment(selectedCutOffDate).format("YYYY-MM-DD"),
                          priceTableId: priceTableId,
                          roundNumber: roundNumber
                        })
                      }
                      variant="primary"
                    >
                      Submit
                    </Button>
                  </td>
                  <td>
                    <Button
                      disabled={!service || !service.id}
                      style={{
                        ...style.submitButton(style.colors.green),
                        marginBottom: 20
                      }}
                      onClick={() => {
                        openVersionControl("service", service.id);
                      }}
                    >
                      Open
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <ToastContainer />
    </>
  );
};

export default ServiceTables;
