import React, { useState, useContext } from "react";
import { Button } from "react-bootstrap";
import CreatNewBotStepModal from "./CreateNewBotStepModal.jsx";

import StyleContext from "../../styling/styleContext";

const NewBotStepButton = ({
  updateBotQuestions,
  type,
  botId,
  openVersionControl
}) => {
  const style = useContext(StyleContext);
  const [modalOpen, toggleModalOpen] = useState(false);

  const toggle = () => toggleModalOpen((prevState) => !prevState);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        textAlign: "right"
      }}
    >
      <Button
        style={{
          ...style.buttonStyle(style.colors.green),
          background: style.colors.green
        }}
        onClick={() => toggle()}
      >
        New Bot Step
      </Button>
      <Button
        disabled={!botId}
        style={{
          ...style.buttonStyle("rgb(252, 220, 105)"),
          color: "white",
          border: "black",
          marginBottom: 2
        }}
        onClick={() => {
          openVersionControl("bot", botId, type);
        }}
      >
        Version Control
      </Button>
      <CreatNewBotStepModal
        updateBotQuestions={updateBotQuestions}
        type={type}
        botId={botId}
        open={modalOpen}
        toggle={toggleModalOpen}
      />
    </div>
  );
};

export default NewBotStepButton;
