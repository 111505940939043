import React, { useEffect, useState, Fragment } from "react";

import { API } from "aws-amplify";

import { Button } from "reactstrap";

import { Form } from "react-bootstrap";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../components/CompanySelector.jsx";
import ResetPassword from "../scenes/settings/ResetPassword.jsx";

export const SettingsScreen = (props) => {
  const [client, setClient] = useState(null);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ name: "", email: "" });
  const [isAdmin, setIsAdmin] = useState(false);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    const fn = async () => {
      const client = await API.get(
        "dashboardapi",
        "/v1/management/settings",
        {}
      );
      setUsers(client.users.items);
      setClient(client);
    };

    fn();
  }, []);
  const createUserCb = async () => {
    try {
      const roles = [];
      if (isAdmin) {
        roles.push("Admin");
      } else {
        roles.push("User");
      }

      const user = await API.post("dashboardapi", "/v1/management/add-user", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId
        },
        body: { ...newUser, roles: JSON.stringify(roles) }
      }).catch((err) => {
        throw Error(err.response.data.message);
      });
      if (user && user.error) {
        throw Error(user.message);
      } else if (user && user.message) {
        const u = {
          ...newUser,
          roles: JSON.stringify(roles)
        };
        const allu = [...users];
        allu.push(u);
        setUsers(allu);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="title">Settings</div>
        <div className="sub-title">{client ? client.name : "  "}</div>
      </div>
      {/* client info */}
      {client ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white card-block">
                <h5>Company Info</h5>
                <strong>{client.name}</strong>
              </div>
            </div>
          </div>

          {/* end client info */}
          {/* branches */}
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white card-block">
                <h5>Connected Branches</h5>
                <ul>
                  {client.branches.items.map((i) => {
                    const key =
                      "branch_id_" + Math.floor(Math.random() * 10000);
                    return (
                      <li key={key}>
                        <strong>{i.name}</strong> (Branch ID: ...
                        {i.builderCompanyId.substring(
                          i.builderCompanyId.length - 10,
                          10
                        )}{" "}
                        ){" "}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/*  end branches */}

          {/* users */}
          <div className="row">
            <div className="col-md-12">
              <div className="card bg-white card-block">
                <h5>Users</h5>
                <ul>
                  {users
                    ? users.map((i) => {
                        const key =
                          "branch_id_" + Math.floor(Math.random() * 10000);
                        const roles = JSON.parse(i.roles);
                        const rolesj = roles.join(", ");

                        return (
                          <li key={key}>
                            {i.name} - {i.email} - {rolesj}
                          </li>
                        );
                      })
                    : null}
                </ul>

                {props.isAdmin && (
                  <Fragment>
                    <input
                      style={{ width: 200 }}
                      value={newUser.email}
                      onChange={(e) => {
                        const payload = { ...newUser };
                        payload.email = e.target.value;
                        setNewUser(payload);
                      }}
                      placeholder={"email address"}
                    />
                    <br />
                    <input
                      style={{ width: 200 }}
                      value={newUser.name}
                      onChange={(e) => {
                        const payload = { ...newUser };
                        payload.name = e.target.value;
                        setNewUser(payload);
                      }}
                      placeholder={"name"}
                    />
                    <Form.Group
                      style={{ marginTop: 10 }}
                      className="mb-3"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check
                        onChange={(e) => {
                          setIsAdmin(e.target.checked);
                        }}
                        type="checkbox"
                        label="Admin"
                      />
                    </Form.Group>
                    <br />
                    <Button onClick={createUserCb} style={{ width: 150 }}>
                      Create User
                    </Button>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
          {/* {
            props.isAdmin ? (
              <ResetPassword users={users} />
            ): null
          } */}
        </>
      ) : null}
      {/* end users */}
    </>
  );
};
