import React from "react";
import PricingForm from "./pricing-form";

const PricingTab = ({ renderHelpButtons, openVersionControl }) => {
  return (
    <PricingForm
      openVersionControl={openVersionControl}
      renderHelpButtons={renderHelpButtons}
    />
  );
};

export default PricingTab;
