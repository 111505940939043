import React, { useEffect, useState, useContext } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Card,
  Spinner,
  AccordionContext
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import * as _ from "lodash";

import "react-toastify/dist/ReactToastify.css";
import "./SortableTable.css";

import { orderData, onDragEnd } from "./Functions";

import DragAndDropContainer from "./DragAndDropContainer";
import Images from "./Images/Images";
import OptionsContainer from "./Options/OptionsContainer";
// import ImageTest from "./Draggables/ImageTest";
import OtherDraggable from "./Draggables/OtherDraggable";
import { API } from "aws-amplify";

import StyleContext from "../../styling/styleContext";

const SortableTableContainer = ({
  data,
  step,
  updateBotQuestions,
  type,
  botId
}) => {
  const [state, setState] = useState([]);
  const [active, setActive] = useState();
  const [hasOption, setHasOption] = useState(false);
  const [hasConditional, setHasConditional] = useState(false);
  const [arr, setArr] = useState([]);

  const notify = (val) => toast(val);

  const saveTable = () => {
    setActive(false);
  };

  useEffect(() => {
    const newData = orderData(data, setArr, setHasOption, setHasConditional);
    setState(newData);
  }, [data]);

  console.log({ data });

  const setButton = () => {
    setActive(true);
  };

  const updateData = (original, newOrder) => {
    let filtered = original.filter(
      ({ id }) => id && newOrder.find((n) => id === n.id)
    );
    let final = [...newOrder, ...filtered];
    return final;
  };

  const saveStep = async (data, state) => {
    console.log({ new: updateData(data, state) });

    console.log("saving", step);
    console.log({ botId });
    // return;
    // const company = await API.get("dashboardapi", "/v1/company", {});
    // const bot = company.bots.items[0];

    // const botQuestions = JSON.parse(bot[type]);

    // const replace = ({ id }, index, arr) => {
    //   if (id === step.id) {
    //     arr[index] = step;
    //   }
    // };

    // botQuestions.forEach(({ probing_questions, steps }) => {
    //   steps.forEach(replace);
    //   probing_questions.forEach(replace);
    // });

    // console.log({ botQuestions, step });

    // const response = await API.put("dashboardapi", "/v1/update", {
    //   body: {
    //     id: botId,
    //     step: botQuestions,
    //     questionGroup: type
    //   }
    // });

    // console.log({ response });
  };

  const handleOnDragEnd = (result) => {
    if (result.destination.index === 0) return false;

    const items = onDragEnd(result, state);

    setState(items);
    setActive(true);
    saveStep(data, items);
    console.log({ data, items });
  };

  const props = {
    data,
    state,
    active,
    hasOption,
    hasConditional,
    arr,
    notify,
    saveTable,
    setButton,
    handleOnDragEnd,
    updateBotQuestions,
    type,
    botId,
    step
  };

  return <SortableTable {...props} />;
};

const SortableTable = ({
  data,
  state,
  active,
  hasOption,
  arr,
  notify,
  saveTable,
  setButton,
  handleOnDragEnd,
  updateBotQuestions,
  type,
  botId,
  step,
  refetch
}) => {
  const [toggled, setToggled] = useState(false);

  const style = useContext(StyleContext);

  const conditionals = data.reduce((allConditionals, { conditionals }) => {
    console.log({ conditionals });
    if (conditionals) {
      allConditionals.push(
        ...conditionals.map(({ matches: label }) => ({ label }))
      );
    }
    return allConditionals;
  }, []);

  const triggers = data.reduce((allTriggers, { trigger: label }) => {
    if (label) {
      allTriggers.push({ label });
    }
    return allTriggers;
  }, []);

  const ImagesContainer = ({ openModal }) => (
    <Images
      data={data}
      openModal={openModal}
      toggled={toggled}
      setToggled={setToggled}
    />
  );

  return (
    <div className="App">
      <div>
        <DragAndDropContainer
          s={data}
          handleOnDragEnd={handleOnDragEnd}
          allSteps={state}
          refetch={refetch}
          updateBotQuestions={updateBotQuestions}
          type={type}
          botId={botId}
          Images={ImagesContainer}
        ></DragAndDropContainer>
        {/* {state.map(step => {
                    if (step.options)
                        return (
                            <OptionsContainer step={step} />

                        )
                })} */}

        {/* <Images data={data} /> */}
        <OtherDraggable step={step} />
      </div>
      {active ? (
        <Button
          style={style.buttonStyle("red")}
          data-testid="button-test"
          onClick={saveTable}
        >
          Save Table
        </Button>
      ) : null}
    </div>
  );
};

export default SortableTableContainer;
