import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AddressBlockWidget } from "../widgets/address-block-widget";
import { CustomerBlockWidget } from "../widgets/customer-block-widget";
import { MesureitImageWidget } from "../widgets/meaurit-image-widget";
import { SimpleAddressMap } from "../widgets/maps/google/simple-address-map";
import { QuoteTabsWidget } from "../widgets/quote-tabs-widget";

// import * as mutations from './../graphql/mutations';
import * as queries from "./../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

import { useParams } from "react-router-dom";
import { CreditCardVaultWidget } from "../widgets/credit-card-vault-widget";
import CreditCardVaultModal from "../widgets/credit-card-vault-modal-widget";
import { getCompanyJson } from "../utils/api-calls";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../components/CompanySelector.jsx";

export const QuoteDetailScreen = (props) => {
  let { id } = useParams();
  const [quote, setQuote] = useState(null);
  const [vaultOpened, setVaultOpened] = useState(false);
  const [isRealGreen, setIsRealGreen] = useState(false);
  const [company, setCompany] = useState(null);
  const [isDontTax, setIsDontTax] = useState(false);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    const run = async () => {
      const result = await API.get(
        "dashboardapi",
        `/v1/dashboard/quote/${id}/details`
      );

      if (result.quote) {
        setQuote(result.quote);
      }

      const branch = props.branches.length
        ? props.branches.find(
            (b) => b.builderCompanyId === result.quote.companyId
          )
        : null;
      if (branch) {
        const status = branch.crmSolution === "real-green";
        setIsRealGreen(status);
      } else {
        setIsRealGreen(false);
      }
      const response = await API.get("dashboardapi", `/v1/companys`, {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId
        }
      });
      const gc = await getCompanyJson(
        result.quote.companyId,
        result.quote.botId
      );

      let donttax = gc.dontTaxZipCodes || [];
      const postalCode = result.quote.address.postalCode;

      if (donttax.includes(postalCode)) {
        gc.serviceTax = 0.0;
        gc.lookupZipCodeTax = false;
        setIsDontTax(true);
      }

      setCompany({
        ...(response.message &&
        response.message.data &&
        response.message.data.getCompanyVersioned
          ? response.message.data.getCompanyVersioned
          : {}),
        ...gc
      });
    };

    run();
  }, [id, props.branches, activeCompanySnapshot?.companyId]);

  const smallCard = {
    borderRadius: 10,
    boxShadow: "2px 2px 2px #C6B8B8",
    minHeight: 300,
    height: 300,
    maxHeight: 300,
    background: `linear-gradient(-180deg, #F5F5F5 20%, #ffffff 20%)`
  };

  return quote && company ? (
    <>
      <div className="page-title">
        <div className="title">Quote Detail</div>
        <div className="sub-title">
          {quote.address.address}
          {quote.address.city} {quote.address.state} {quote.address.postalCode}
        </div>
      </div>
      <div className="row">
        <div className="col-md-8">
          <CustomerBlockWidget
            quote={quote}
            openCb={setVaultOpened}
            isRealGreen={isRealGreen}
            company={company}
          />
          {quote.convertedSale ? (
            <div className={`col-sm-2`}>
              <CreditCardVaultModal
                isOpen={vaultOpened}
                closeModal={setVaultOpened}
                quote={quote}
              />
            </div>
          ) : null}

          <div
            className="card card-block card-white"
            style={{ borderRadius: 10, boxShadow: "2px 2px 2px #C6B8B8" }}
          >
            <QuoteTabsWidget
              quote={quote}
              company={company}
              dontTax={isDontTax}
            />
          </div>
          {quote.convertedSale ? (
            <div className="row">
              <div className="col-md-6">
                <div className="card card-block card-white" style={smallCard}>
                  <div style={{ fontWeight: "bolder", marginBottom: 25 }}>
                    Audit Log
                  </div>
                  <div style={{ overflow: "auto" }}>
                    <ul className="dashed">
                      <li>One</li>
                      <li>One</li>
                      <li>One</li>
                      <li>One</li>
                      <li>One</li>
                      <li>One</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-block card-white" style={smallCard}>
                  <div style={{ fontWeight: "bolder", marginBottom: 25 }}>
                    Service Questions
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-md-4">
          <MesureitImageWidget quote={quote} />
          {quote.address.location ? (
            <SimpleAddressMap
              googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCsvXcHXmswWZAi443G53uNl11Q7Pfxn0s"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `45%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              center={{
                lat: quote.address.location.lat,
                lng: quote.address.location.lon
              }}
              markers={[
                {
                  coords: {
                    lat: quote.address.location.lat,
                    lng: quote.address.location.lon
                  },
                  convertedSale: quote.convertedSale
                }
              ]}
              zoom={18}
              style={{ height: 200 }}
            />
          ) : null}
        </div>
      </div>
    </>
  ) : null;
};
