import React, { useState, useEffect } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsconfig from "../../aws-exports.js";
import { useAppContext } from "../../libs/context";
import { useFormFields } from "../../libs/hooksLib";
import { Link, useHistory, withRouter } from "react-router-dom";
import ButtonLoad from "../ButtonLoad.js";
//import {useForm} from "react-hook-form";

import "./signIn.css";

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const CustomSignIn = () => {
  const [user, setUser] = useState();
  const { userHasAuthenticated } = useAppContext();
  const [newPass, setNewPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState("");
  //const {register, handleSubmit, watch, errors } = useForm();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    newPassword: ""
  });
  const [wrongLogin, setWrongLogin] = useState(false);
  const [wrongPass, setWrongPass] = useState(false);

  const history = useHistory();

  const [videoBuster] = useState("buster" + Math.random() * 200000);

  /* useEffect(() => {
   
   }, []) */

  function validateSignInForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleNewPassSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    Auth.completeNewPassword(
      user, // the Cognito User Object
      fields.newPassword // the new password
    )
      .then((user) => {
        // at this time the user is logged in if no MFA required
        //isLoading(true)

        window.location = "/";
      })
      .catch((e) => {
        // new password error

        if (e.code === "InvalidPasswordException") {
          setWrongPass(true);
          setPassErrorMsg(e.message);
        }
      });
  }

  async function handleFormSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const user = await Auth.signIn(fields.email, fields.password);
      setUser(user);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setNewPass(true);
        setIsLoading(false);
        //const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      } else {
        // other situations
        window.location = "/";
      }
      //userHasAuthenticated(true);
      // TO DO: find fix for history.push
      //history.push("/");
    } catch (e) {
      //onError(e);

      if (e.code === "NotAuthorizedException") {
        setWrongLogin(true);
        setPassErrorMsg(e.message);
      } else if (e.code === "IInvalidPasswordException") {
        setWrongPass(true);
        setPassErrorMsg(e.message);
      }

      setIsLoading(false);
      //alert("wrong cridentials")
    }
  }

  function renderSignInForm() {
    return (
      <div className="body">
        <div className="left-div">
          <img className="logo" src="/servicebot-logo-small.png" alt="" />
          <div className="instructions">Log into your Servicebot account</div>
          <form className="form" onSubmit={handleFormSubmit}>
            <input
              className="input"
              type="email"
              placeholder="e-mail"
              value={fields.email}
              name="email"
              id="email"
              onChange={handleFieldChange}
            />

            {/*!validateSignInForm() &&
                <p className="error">Email is required</p>
              */}

            <input
              className="input"
              type="password"
              id="password"
              placeholder="password"
              value={fields.password}
              name="password"
              onChange={handleFieldChange}
            />
            {/*!validateSignInForm() &&
                <p className="error">Password is required</p>
                */}

            <Link to="/reset">Forgot password?</Link>
            <ButtonLoad
              className="signIn-button"
              type="submit"
              isLoading={isLoading}
              disabled={!validateSignInForm()}
              text={"SIGN IN"}
            ></ButtonLoad>
            {wrongLogin && <p className="error">{passErrorMsg}</p>}
          </form>
          <div className="no-account">
            <div>{"Don't have an account?"} </div>
            <a href="https://goservicebot.com/">Get Started</a>
          </div>

          <img src="/madein.png" alt="" className="footer-img" />
        </div>

        <div className="right-div">
          <div className="announcement">
            <iframe
              className="iframe"
              id="announcement-Iframe-2"
              title="lawnbot-welcome-video"
              src={`https://lawnbot.biz/announcements/lawnbot_dashboard_welcome.html?buster=${videoBuster}_iframe`}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  function renderNewPasswordForm() {
    return (
      <div className="body">
        <div className="left-div">
          <img className="logo" src="/lawnbotnew.png" alt="" />
          <div className="instructions">Create your new password</div>
          <form className="form" onSubmit={handleNewPassSubmit}>
            <input
              className="input"
              type="password"
              id="newPassword"
              placeholder="new password"
              value={fields.newPassword}
              name="newPassword"
              onChange={handleFieldChange}
            />
            {wrongPass && <p className="error">{passErrorMsg}</p>}

            <Link to="/">Back to login</Link>
            <ButtonLoad
              className="signIn-button"
              type="submit"
              isLoading={isLoading}
              //disabled={!validateNewPassword()}
              text={"Change Password"}
            ></ButtonLoad>
          </form>

          <img src="/madein.png" alt="" className="footer-img" />
        </div>

        <div className="right-div">
          <div className="announcement">
            <iframe
              title={"lawnbot-announcements"}
              className="iframe"
              id="announcement-Iframe"
              src="https://lawnbot.biz/announcements/lawnbot_fb_embed.html?buster=61170_iframe"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  return <>{newPass ? renderNewPasswordForm() : renderSignInForm()}</>;
};
export default CustomSignIn;
