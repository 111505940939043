import React, { useState, useEffect } from "react";
import Amplify, { Auth, Hub } from "aws-amplify";
import awsmobile from "../../aws-exports.js";
import { useFormFields } from "../../libs/hooksLib";
import "./forgotPass.css";
import { Link } from "react-router-dom";
import ButtonLoad from "../ButtonLoad";
import { Button } from "react-bootstrap";

Amplify.configure(awsmobile);
Auth.configure(awsmobile);

const ForgotPass = () => {
  const [fields, handleFieldChange] = useFormFields({
    code: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const [codeSent, setCodeSent] = useState(false);
  const [forcePasswordResetError, setforcePasswordResetError] = useState('')
  const [confirmed, setConfirmed] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [userFound, setUserFound] = useState(true);
  const [wrongPass, setWrongPass] = useState(false);
  const [passErrorMsg, setPassErrorMsg] = useState("");
  const [wrongCode, setWrongCode] = useState(false);

  /*
  useEffect(() => {
    validateResetForm();
  }, [setNotIdentical])
*/
  function validateCodeForm() {
    return fields.email.length > 0;
  }

  function validatePassCode() {
    return (
      fields.password === fields.confirmPassword &&
      fields.password.length > 0 &&
      fields.confirmPassword.length > 0
    );
  }

  function validateCodeLength() {
    return fields.code.length > 0;
  }

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_API}/v1/management/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          },
          body: JSON.stringify({ email: fields.email })
        }
      );
      const data = await response.json();

      if (response.status === 200) {
        await Auth.forgotPassword(fields.email);
        setCodeSent(true);
      } else {
        setforcePasswordResetError(data.message);
        setIsSendingCode(false);
      }

    } catch (error) {
      console.log("error", error)
      //onError(error);
      if (error.code === "UserNotFoundException") {
        setUserFound(false);
      }
      setIsSendingCode(false);
    }
  }

  async function resendCode() {
    try {
      await Auth.forgotPassword(fields.email);
      //setIsConfirming(true);
    } catch (error) {
      //setIsConfirming(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    setIsConfirming(true);

    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.code,
        fields.password
      );
      setConfirmed(true);
    } catch (error) {
      //onError(error);
      setIsConfirming(false);

      if (error.code === "CodeMismatchException") {
        setWrongCode(true);
      } else if (error.code === "InvalidPasswordException") {
        setPassErrorMsg(error.message);
        setWrongPass(true);
      }
    }
  }

  const handleForcePasswordResetEmailChange = (event) => {
    if (forcePasswordResetError) {
      setforcePasswordResetError('')
    }
    handleFieldChange(event)
  }

  function renderRequestCodeForm() {
    return (
      <div className="left-div">
        <img className="logo" src="/servicebot-logo-small.png" alt="" />
        <div className="instructions">Enter your Email </div>
        <form className="form" onSubmit={handleSendCodeClick}>
          <input
            className="input"
            name="email"
            type="email"
            id="email"
            value={fields.email}
            onChange={handleForcePasswordResetEmailChange}
            placeholder="e-mail"
          />
          {forcePasswordResetError && <p className="error">{forcePasswordResetError}</p>}
          <Link className="link-back" to="/">
            Back to login
          </Link>
          <ButtonLoad
            type="submit"
            isLoading={isSendingCode}
            disabled={!validateCodeForm()}
            className="signIn-button"
            text="Send Code"
          ></ButtonLoad>
        </form>

        <img src="/madein.png" alt="" className="footer-img" />
      </div>
    );
  }

  function renderConfirmationForm() {
    return (
      <div className="left-div">
        <img className="logo" src="/lawnbotnew.png" alt="" />
        <div className="instructions1">
          Please check your email for confirmation code{" "}
        </div>
        <div className="instructions2">Then enter your new password</div>
        <form onSubmit={handleConfirmClick}>
          <input
            className="input"
            type="tel"
            id="code"
            value={fields.code}
            onChange={handleFieldChange}
            placeholder="confirmation code"
          />
          {wrongCode && <p className="error">Wrong code</p>}
          {/*!validateCodeLength() && <p className="error">Enter a code</p>*/}
          <input
            className="input"
            type="password"
            id="password"
            value={fields.password}
            onChange={handleFieldChange}
            placeholder="new password"
          />
          <input
            className="input"
            type="password"
            id="confirmPassword"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
            placeholder="confirm password"
          />
          {!validatePassCode() && fields.password && fields.confirmPassword && (
            <p className="error">Passwords do not match</p>
          )}
          {wrongPass && <p className="error">{passErrorMsg}</p>}
          <Link className="link-back" to="/">
            Back to login
          </Link>
          <ButtonLoad
            type="submit"
            isLoading={isConfirming}
            disabled={!validatePassCode() || !validateCodeLength()}
            className="signIn-button"
            text="Confirm"
          ></ButtonLoad>
        </form>
        <div className="resend-code">
          <div>{"Didn't get an email?"} </div>
          <Button variant="link" onClick={resendCode}>
            Resend Code
          </Button>
        </div>
        <img src="/madein.png" alt="" className="footer-img" />
      </div>
    );
  }

  function renderSuccessMessage() {
    return (
      <div className="left-div">
        <img className="logo" src="/lawnbotnew.png" alt="" />
        <div className="instructions-success">Your password has been reset</div>

        <Link className="link-success" to="/">
          Back to login
        </Link>

        <img src="/madein.png" alt="" className="footer-img" />
      </div>
    );
  }

  return (
    <div className="body">
      {!codeSent
        ? renderRequestCodeForm()
        : !confirmed
          ? renderConfirmationForm()
          : renderSuccessMessage()}

      <div className="right-div">
        <div className="announcement">
          <iframe
            title={"lawnbot-announcments"}
            className="iframe"
            id="announcement-Iframe"
            src="https://lawnbot.biz/announcements/lawnbot_fb_embed.html?buster=61170_iframe"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ForgotPass;
