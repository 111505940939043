import React from "react";
import PropTypes from "prop-types";

export const KpiBlockWidget = (props) => {
  return (
    <div
      style={{ cursor: props.link ? "pointer" : "auto" }}
      className={`col-sm-${props.cols}`}
      onClick={() => {
        if (!props.link) return;
        window.location = props.link;
      }}
    >
      <div
        className={`card card-block no-border`}
        style={{
          backgroundColor: props.bgColor,
          color: "#000000",
          fontFamily: "VAG-Rounded-Std",
          fontWeight: "bold",
          fontStyle: "normal"
        }}
      >
        <h5
          className={`m-a-0`}
          style={{
            color: "#000000",
            fontFamily: "VAG-Rounded-Std",
            fontWeight: "bold",
            fontStyle: "normal"
          }}
        >
          {props.label}
        </h5>
        <h1
          className={`m-a-0`}
          style={{
            fontFamily: "VAG-Rounded-Std",
            fontWeight: "bold",
            fontStyle: "normal"
          }}
        >
          {props.value.toLocaleString()}
        </h1>
      </div>
    </div>
  );
};

KpiBlockWidget.propTypes = {
  cols: PropTypes.number.isRequired,
  colorClass: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
