const keyDeletion = {
  all: ["version", "createdAt", "updatedAt", "isDeleted"],
  companyBranding: [
    "company",
    "instaQuoteMessage",
    "aboutUsAssetType",
    "companyBrandingCompanyId"
  ],
  company: [
    "branding",
    "offers",
    "dataServices",
    "bots",
    "programs",
    "priceTables"
  ],
  companyPricing: [
    "branding",
    "offers",
    "dataServices",
    "bots",
    "programs",
    "priceTables",
    "companyName",
    "customerName",
    "customerEmail",
    "customerPhone",
    "customerAddress",
    "customerCity",
    "customerState",
    "customerZip",
    "companyBrandingId",
    "companyDataServicesId"
  ],
  bot: ["company", "sections", "preModal", "serviceQuestions", "objections"],
  service: ["program", "serviceProgramId", "priceTableId"],
  priceTable: ["company", "rows"],
  program: [
    "company",
    "services",
    "probingQuestions",
    "useProbingQuestions",
    "useRealGreenPricingEngine",
    "programProbingQuestionsId",
    "realGreenApplicationDiscountCode",
    "realGreenPrepayDiscountCode",
    "programCompanyId",
    "probingQuestionsJson",
    "conditions",
    "alwaysOnTop",
    "alwayAvailable",
    "alternativeApplicationString"
  ]
};

export const deleteKeys = (data, type) => {
  const result = { ...data };
  keyDeletion["all"].forEach((key) => {
    delete result[key];
  });
  keyDeletion[type].forEach((key) => {
    delete result[key];
  });

  return result;
};
