import React, { useContext } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { RemoveButton } from "../remove-button/RemoveButton";
import StyleContext from "../../../styling/styleContext";

export const Conditionals = ({
  index,
  key,
  itemValue,
  remove,
  specialStepChange
}) => {
  const style = useContext(StyleContext);

  return (
    <>
      <div>
        {/* <Form.Label>{key} - Matches</Form.Label>
      <Form.Control
        defaultValue={itemValue.matches}
        onChange={(e) => {
          specialStepChange(e, index, "matches");
        }}
        name={key}
      />
    </div> */}
        {/* <div className="mt-2"> */}
        <Form.Label
          style={{
            ...style.styleSizing,
            color: style.colors.blue,
            margin: 0,
            fontSize: 18,
            marginTop: 15
          }}
        >
          {key}Trigger
        </Form.Label>
        <InputGroup>
          <Form.Control
            style={{
              ...style.styleSizing,
              color: style.colors.green,
              margin: 0,
              fontSize: 14
            }}
            defaultValue={itemValue.trigger}
            onChange={(e) => {
              specialStepChange(e, index, "trigger");
            }}
            name={key}
          />
          <RemoveButton onClick={remove} />
        </InputGroup>
      </div>
      <br />
    </>
  );
};
