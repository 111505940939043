import React, { useState, useEffect, useContext } from "react";
import { Input } from "reactstrap";
import { Form } from "react-bootstrap";
import StyleContext from "../../../styling/styleContext";

import "./discount-table.css";

const DiscountTable = ({ rounds, discountCodes, setRounds }) => {
  const style = useContext(StyleContext);
  const [rows, setRows] = useState([]);
  const addRow = () => {
    const item = {
      round: 0,
      code:
        discountCodes && discountCodes[0] ? discountCodes[0].id.trim() : null,
      isSlidingScale: false,
      sqftLowerRange: 0,
      sqftUpperRange: 1000,
      discountData: {}
    };

    setRounds([...rows, item]);
  };

  useEffect(() => {
    if (rounds) {
      setRows(rounds);
    }
  }, [rounds]);

  const handleDeleteRow = (idx) => () => {
    const temp = [...rows];
    temp.splice(idx, 1);

    if (!temp.length) {
      setRounds([]);
    } else {
      setRounds(temp);
    }
  };
  const fontStyle = {
    padding: 10,
    marginLeft: 5,
    fontSize: "14px",
    color: "whitesmoke"
  };

  return (
    <>
      <h3 style={{ ...style.styleSizing, color: style.colors.blue }}>
        Add Discounts
      </h3>
      <div>
        <div className="container">
          <div>
            <div>
              <table className="table table-bordered table-hover">
                <thead style={{ background: "rgb(53, 180, 89)" }}>
                  <tr>
                    <th style={fontStyle}>
                      <i style={{ margin: 5 }} className="fa fa-list-ol"></i>
                      Round
                    </th>
                    <th style={{ ...fontStyle, textAlign: "center" }}>
                      <i style={{ margin: 5 }} className="fa fa-usd">
                        {" "}
                      </i>{" "}
                      Discount Code{" "}
                    </th>
                    <th style={{ ...fontStyle, textAlign: "center" }}>
                      Lower SQFT Range
                    </th>
                    <th style={{ ...fontStyle, textAlign: "center" }}>
                      Upper SQFT Range
                    </th>
                    <th style={{ ...fontStyle, textAlign: "center" }}>
                      Apply to Available Rounds
                    </th>
                    <th style={fontStyle}>
                      <i style={{ margin: 5 }} className="fa fa-trash">
                        {" "}
                      </i>{" "}
                      Delete{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows?.length &&
                    rows.map((item, idx) => (
                      <tr id="addr0" key={`table_key_${idx}`}>
                        <td>
                          <Form.Control
                            required
                            min="0"
                            style={{
                              ...style.dropDown,
                              width: 65,
                              paddingInline: "5px 5px",
                              backgroundPosition: "calc(100% - 5px) center"
                            }}
                            value={item.round}
                            onChange={(e) => {
                              const newRounds = [...rounds];
                              newRounds[idx].round = e.target.value;
                              if (setRounds) {
                                setRounds(newRounds);
                              }
                            }}
                            type="number"
                            placeholder="enter round number"
                          />
                          <Form.Control.Feedback type="invalid">
                            Round must be a positive integer.
                          </Form.Control.Feedback>
                        </td>
                        <td>
                          <Form.Control
                            as="select"
                            style={{
                              ...style.dropDown,
                              backgroundPosition: "calc(100% - 20px) center"
                            }}
                            onChange={(e) => {
                              const newRounds = [...rounds];
                              newRounds[idx].code = e.target.value;
                              newRounds[idx].discountData = discountCodes.find(
                                (i) => {
                                  return i.id === e.target.value && i.available;
                                }
                              );
                              if (setRounds) {
                                setRounds(newRounds);
                              }
                            }}
                            value={item.code ? item.code : ""}
                          >
                            {discountCodes
                              .filter((a) => a.available)
                              .filter((a) => a.discountDescription.trim())
                              .map((a, index) => {
                                const discountAmount = a.discountAmount;
                                const discountType =
                                  a.dollarDiscount === 1
                                    ? discountAmount + "% Off"
                                    : "$" + discountAmount + " Off";
                                return (
                                  <option
                                    key={`options_key ${index}`}
                                    value={a.id}
                                  >
                                    {a.discountDescription} ({discountType})
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            required
                            min="0"
                            max={item.sqftUpperRange}
                            style={{
                              ...style.dropDown,
                              width: 85,
                              paddingInline: "5px 5px",
                              backgroundPosition: "calc(100% - 5px) center"
                            }}
                            value={item.sqftLowerRange}
                            onChange={(e) => {
                              const newRounds = [...rounds];
                              newRounds[idx].sqftLowerRange = e.target.value;
                              if (setRounds) {
                                setRounds(newRounds);
                              }
                            }}
                            type="number"
                            placeholder="enter round number"
                          />
                          <Form.Control.Feedback type="invalid">
                            Number must be positive and less than Upper Sqft
                            Range.
                          </Form.Control.Feedback>
                        </td>
                        <td>
                          <Form.Control
                            required
                            min={item.sqftLowerRange}
                            style={{
                              ...style.dropDown,
                              width: 85,
                              paddingInline: "5px 5px",
                              backgroundPosition: "calc(100% - 5px) center"
                            }}
                            value={item.sqftUpperRange}
                            onChange={(e) => {
                              const newRounds = [...rounds];
                              newRounds[idx].sqftUpperRange = e.target.value;
                              if (setRounds) {
                                setRounds(newRounds);
                              }
                            }}
                            type="number"
                            placeholder="enter round number"
                          />
                          <Form.Control.Feedback type="invalid">
                            Number must be positive and greater than Lower Sqft
                            Range.
                          </Form.Control.Feedback>
                        </td>
                        <td>
                          <Form.Group style={{ textAlign: "center" }}>
                            <Form.Check
                              type="checkbox"
                              checked={item.isSlidingScale}
                              onChange={() => {
                                const newRounds = [...rounds];
                                newRounds[idx].isSlidingScale =
                                  !item.isSlidingScale;
                                if (setRounds) {
                                  setRounds(newRounds);
                                }
                              }}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <button
                            type="button"
                            onClick={handleDeleteRow(idx)}
                            className="btn btn-outline-danger btn-sm"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div style={{ display: "flex" }}>
                <button
                  type="button"
                  style={style.submitButton(style.colors.green)}
                  onClick={addRow}
                  className="add-delete-button"
                >
                  Add Row
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscountTable;
