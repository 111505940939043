import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import React, { useState } from "react";
import PropTypes from "prop-types";

export const FilterWidget = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      style={{
        borderRadius: 10,
        display: "inline-block",
        width: 200,
        marginTop: 6
      }}
    >
      <DropdownToggle
        caret
        style={{ backgroundColor: "white", color: "black", borderRadius: 10 }}
      >
        <span
          style={{
            paddingRight: 20,
            fontFamily: "'Proxima Nova'",
            color: "#B2B2B2"
          }}
        >
          {props.title}
        </span>
      </DropdownToggle>
      <DropdownMenu>
        {props.options.map((i) => {
          const key = "filter_option_" + Math.floor(Math.random() * 100000);
          return (
            <DropdownItem
              key={key}
              style={{ fontFamily: "'Proxima Nova'" }}
              onClick={
                props.clickCb
                  ? () => {
                      props.clickCb(i);
                    }
                  : () => {
                      console.log("item clicked", i);
                    }
              }
            >
              {i}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

FilterWidget.propTypes = {
  options: PropTypes.array.isRequired,
  style: PropTypes.object,
  clickCb: PropTypes.func,
  title: PropTypes.string.isRequired
};
