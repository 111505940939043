import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as _ from "lodash";

export const DonutPieChart = (props) => {
  const [series, setSeries] = useState([]);
  const key = Math.floor(Math.random()) + "donut_chart";

  const boxref = useRef();

  useState(() => {
    setSeries(props.series);
    const win = window.jQuery(`#${key}`);

    setTimeout(() => {
      if (!boxref.current) return;
      console.log("loading ", key);
      window.jQuery.plot(
        window.jQuery(boxref.current),
        props.series,

        {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true
            }
          },
          legend: {
            show: false
          }
        }
      );
    }, 300);
  }, [props.series]);

  return (
    <div key={key} className="overflow-hidden" style={{ marginBottom: 15 }}>
      <div
        className="text-white "
        style={{ backgroundColor: "#ffffff", border: 0 }}
      >
        <h4
          className=""
          style={{
            marginTop: 10,
            marginLeft: 10,
            color: "#4E7CFF",
            fontWeight: "bold"
          }}
        >
          {/* <!-- <span className="pull-right">200 Chats This week</span> --> */}
          <span>{props.title}</span>
        </h4>
        <div
          id={key}
          ref={boxref}
          className="chart labels-white"
          style={{ height: 300 }}
        ></div>
      </div>
    </div>
  );
};

DonutPieChart.propTypes = {
  title: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired
};
