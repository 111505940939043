import React, { useContext, useState, Fragment } from "react";
import PublishModal from "./PublishModal";
import { StyleContext } from "../../styling/styleContext";
import { Button } from "react-bootstrap";

const PublishButton = () => {
  const style = useContext(StyleContext);
  const [confirm, setConfirm] = useState(false);
  const openConfirm = () => setConfirm(true);
  const closeConfirm = () => setConfirm(false);
  const onConfirm = () => {
    closeConfirm();
    alert("Bot Updated");
  };
  const onCancel = () => {
    closeConfirm();
  };
  return (
    <Fragment>
      <Button
        className="float-right"
        onClick={openConfirm}
        style={{
          background: "#f50057",
          borderColor: "black",
          borderRadius: 3
        }}
      >
        Publish Bot(s)
      </Button>
      <PublishModal
        onConfirm={onConfirm}
        onCancel={onCancel}
        show={confirm}
        hide={closeConfirm}
      />
    </Fragment>
  );
};

export default PublishButton;
