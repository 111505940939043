/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_REGION ?? "us-east-1",
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_DASHBOARD_IDENTITY_POOL ??
    "us-east-1:f3521428-4b70-46f3-9050-b1c4e211f376",
  aws_cognito_region: process.env.REACT_APP_REGION ?? "us-east-1",
  aws_user_pools_id:
    process.env.REACT_APP_DASHBOARD_USER_POOL ?? "us-east-1_5zEEtmbwg",
  aws_user_pools_web_client_id:
    process.env.REACT_APP_DASHBOARD_WEB_CLIENT_ID ??
    "5jv4km9a4b76h2v6pvao43niec",
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_DASHBOARD_GRAPHQL_ENDPOINT ??
    "https://u2ionp7zz5fbvnqcgvanerssfy.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: process.env.REACT_APP_REGION ?? "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey:
    process.env.REACT_APP_DASHBOARD_GRAPHQL_KEY ??
    "da2-l5m44pyiong5vezn2uqzqig3xu",
  aws_cloud_logic_custom: [
    {
      name: "dashboardapi",
      endpoint: process.env.REACT_APP_DASHBOARD_API,
      region: process.env.REACT_APP_REGION
    }
  ]
};

export default awsmobile;
