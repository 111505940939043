import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { API } from "aws-amplify";
import {
  getCompanyEmployees,
  getCompanyMarketingCodes,
  getCompanyCallActionReasons,
  getCompanyCallStatuses,
  getCompanies,
  getCompanyRoutes
} from "./api_calls";
import "./real-green-crm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StyleContext from "../../styling/styleContext";
import { useSnapshot } from "valtio";
import { activeCompanyState } from "../../components/CompanySelector.jsx";

const statuses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
  return { value: `${i}`, label: `${i}` };
});

function RealGreenCRM({ renderHelpButtons }) {
  const [company, setCompany] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [realGreenInfo, setRealGreenInfo] = useState({});
  const [dataServices, setDataServices] = useState({});
  const [employees, setEmployees] = useState([]);
  const [marketingCodes, setMarketingCodes] = useState([]);
  const [callReasons, setCallReasons] = useState([]);
  const [callStatuses, setCallStatuses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [routes, setRoutes] = useState([]);

  const [snackOpened, openSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("Settings Saved");

  const style = useContext(StyleContext);
  const activeCompanySnapshot = useSnapshot(activeCompanyState);

  useEffect(() => {
    const fn = async () => {
      setLoaded(false);
      const company = await API.get("dashboardapi", "/v1/company", {
        headers: {
          "Company-Id": activeCompanySnapshot?.companyId
        }
      });
      company.crmCompanyId =
        typeof company.crmCompanyId === "undefined"
          ? "-1"
          : company.crmCompanyId + "";
      setCompany(company);
      const ds = company.dataServices || {
        stripeTestSecret: "",
        stripeSecret: "",
        realGreenAPIKey: "",
        zillowAPIKey: ""
      };
      setDataServices(ds);
      setRealGreenInfo({
        lawnbotEmployee: company.lawnbotEmployee || null,
        marketingSource: company.marketingSource || null,
        callStatusCode: company.callStatusCode || null,
        callActionReason: company.callActionReason || null,
        crmNewRecordStatusScode: company.crmNewRecordStatusScode || "0",
        crmQuoteStatusCode: company.crmQuoteStatusCode || "2",
        crmSaleStatusCode: company.crmSaleStatusCode || "9",
        crmCompanyId:
          typeof company.crmCompanyId === "undefined"
            ? "-1"
            : company.crmCompanyId + "",
        crmRoute: company.crmRoute || null
      });

      const rgKey = ds.realGreenAPIKey || null;
      if (rgKey) {
        Promise.all([
          getCompanyEmployees(rgKey),
          getCompanyMarketingCodes(rgKey),
          getCompanyCallActionReasons(rgKey),
          getCompanyCallStatuses(rgKey),
          getCompanies(rgKey),
          getCompanyRoutes(rgKey)
        ]).then(
          ([
            lemployees,
            marketingcodes,
            callreasons,
            callstatuses,
            lcompanies,
            lroutes
          ]) => {
            setEmployees(lemployees);
            setMarketingCodes(marketingcodes);
            setCallReasons(callreasons);
            setCallStatuses(callstatuses);
            setCompanies(lcompanies);
            setRoutes(lroutes);
            setLoaded(true);
          }
        );
      }
    };

    fn();
  }, [activeCompanySnapshot?.companyId]);

  const saveRealGreenCompanyData = async () => {
    const payload = { ...company };
    delete payload["bots"];
    delete payload["dataServices"];
    delete payload["branding"];
    delete payload["priceTables"];
    delete payload["programs"];
    delete payload["offers"];
    await API.post("dashboardapi", "/v1/companyCRM", {
      headers: {
        "Company-Id": activeCompanySnapshot?.companyId
      },
      body: { data: { payload } }
    });
    //   window.location = window.location + '';
    toast("Settings saved.");
  };

  return loaded ? (
    <div className="container">
      <ToastContainer />
      <div id="crm-main-content" className="container">
        <Form
          style={{ background: "white", borderRadius: "25px", padding: "30px" }}
        >
          <h4
            style={{ ...style.styleSizing, marginBottom: 30, fontSize: 20 }}
            id="real-green-header"
          >
            RealGreen Settings
          </h4>
          <div>
            <Form.Group controlId="companyId">
              <Form.Label
                id="company-id"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Company ID
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="Select Company"
                as="select"
                value={company.crmCompanyId || null}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                onChange={(e) => {
                  setCompany({ ...company, crmCompanyId: e.target.value });
                }}
                custom
              >
                <option key={"defaultcompid"} value={-1}>
                  No Company Selected
                </option>
                {companies.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.comp_id}>
                      {i.compname + " ( " + i.comp_id + " ) "}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="employee">
              <Form.Label
                id="lawnbot-employee"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Lawnbot Employee
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="Lawnbot"
                value={company.lawnbotEmployee || null}
                as="select"
                onChange={(e) => {
                  setCompany({ ...company, lawnbotEmployee: e.target.value });
                }}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                custom
              >
                {" "}
                {employees.map((i) => {
                  const key = "employee-" + i.name + i.emp_id;
                  return (
                    <option key={key} value={i.emp_id.trim()}>
                      {i.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="route">
              <Form.Label
                style={{ ...style.styleSizing, color: style.colors.blue }}
                id="route"
                className="context"
              >
                Route
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="Lawnbot"
                as="select"
                value={company.crmRoute || null}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                onChange={(e) => {
                  setCompany({ ...company, crmRoute: e.target.value });
                }}
                custom
              >
                {routes.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.route.trim()}>
                      {i.route_desc || i.route}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="marketing-source">
              <Form.Label
                id="marketing-source"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Marketing Source
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="Chatbot"
                value={company.marketingSource || null}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                as="select"
                f
                onChange={(e) => {
                  setCompany({ ...company, marketingSource: e.target.value });
                }}
                custom
              >
                {marketingCodes.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.sourcecd + ""}>
                      {i.source}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>

          <div>
            <Form.Group controlId="call-status">
              <Form.Label
                id="sale-call-status"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Sale Call Status
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="Sales Call"
                value={company.callStatusCode || null}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                as="select"
                onChange={(e) => {
                  setCompany({ ...company, callStatusCode: e.target.value });
                }}
                custom
              >
                {callStatuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.status + ""}>
                      {i.statusdesc}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="call-action-reason">
              <Form.Label
                id="sale-call-action-reason"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Sale Call Action Reason
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="Sales Call"
                as="select"
                value={company.callActionReason || null}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                onChange={(e) => {
                  setCompany({ ...company, callActionReason: e.target.value });
                }}
                custom
              >
                {callReasons.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.actreasid + ""}>
                      {i.actreas}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group className="crm-portal-form" controlId="crmPortalUrl">
              <Form.Label
                id="realgreen-customer-portal"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                RealGreen Customer Portal
              </Form.Label>
              <Form.Control
                type="text"
                className="crm-text-form"
                value={company.crmPortalUrl || ""}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                onChange={(e) => {
                  setCompany({ ...company, crmPortalUrl: e.target.value });
                }}
                onBlur={(e) => {
                  setCompany({ ...company, crmPortalUrl: e.target.value });
                }}
                placeholder="https://"
              ></Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group controlId="newRecordStatus">
              <Form.Label
                id="new-record-status"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                New Record Status
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="0"
                as="select"
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                value={company.crmNewRecordStatusScode}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    crmNewRecordStatusScode: e.target.value
                  });
                }}
                custom
              >
                {statuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.value}>
                      {i.label}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>

          <div>
            <Form.Group controlId="crmQuoteStatusCode">
              <Form.Label
                id="quote-received-status"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Quote Received Status
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="0"
                as="select"
                value={company.crmQuoteStatusCode || "1"}
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                onChange={(e) => {
                  setCompany({
                    ...company,
                    crmQuoteStatusCode: e.target.value
                  });
                }}
                custom
              >
                {statuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.value}>
                      {i.label}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>

          <div>
            <Form.Group controlId="crmSaleStatusCode">
              <Form.Label
                id="sales-made-status"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                Sale Made Status
              </Form.Label>
              <Form.Control
                className="real-green-select"
                placeholder="0"
                as="select"
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                value={company.crmSaleStatusCode}
                onChange={(e) => {
                  setCompany({ ...company, crmSaleStatusCode: e.target.value });
                }}
                custom
              >
                {statuses.map((i) => {
                  const key = "menu-item-" + Math.floor(Math.random() * 100000);
                  return (
                    <option key={key} value={i.value}>
                      {i.label}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </div>
          <div>
            <Form.Group className="crm-portal-form" controlId="crmRoute">
              <Form.Label
                id="realgreen-route-label"
                className="context"
                style={{ ...style.styleSizing, color: style.colors.blue }}
              >
                RealGreen Route Label
              </Form.Label>
              <Form.Control
                type="text"
                className="crm-text-form"
                value={
                  typeof company.crmRoute !== "undefined"
                    ? company.crmRoute
                    : "null"
                }
                style={{
                  ...style.styleSizing,
                  color: style.colors.green,
                  fontSize: 14
                }}
                onChange={(e) => {
                  setCompany({ ...company, crmRoute: e.target.value });
                }}
                onBlur={(e) => {
                  setCompany({ ...company, crmRoute: e.target.value });
                }}
                placeholder="XXX - Example Route"
              ></Form.Control>
            </Form.Group>
          </div>
          <Button
            id="crm-button"
            color="primary"
            variant="contained"
            onClick={saveRealGreenCompanyData}
            style={style.submitButton(style.colors.green)}
          >
            Save
          </Button>
        </Form>
      </div>
    </div>
  ) : (
    <div id="crm-spinner">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

export default RealGreenCRM;
