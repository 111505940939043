import React, { Fragment } from "react";
import { Form, FormControl } from "react-bootstrap";
import './ImageOptions.css'

const ImageOptions = ({ newOption, handleOptionsChange }) => {
  const onChange = (e) => {
    const name = e.target.files[0].name;
    const lastDot = name.lastIndexOf(".");
    const ext = name.substring(lastDot + 1);
    let contentType = `image/${ext}`;
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      handleOptionsChange({
        target: {
          name: "image",
          value: {
            name,
            contentType,
            file: reader.result
          }
        }
      });
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <Fragment>
      <FormControl
        className='modal-input'
        type="text"
        name="value"
        placeholder="new option value"
        value={newOption.value}
        onChange={handleOptionsChange}
      />
      <FormControl
        className='modal-input'
        onChange={handleOptionsChange}
        type="text"
        name="text"
        placeholder="new option label"
        value={newOption.text}
      />
      <div>
        <h6 style={{ marginBottom: 5, marginTop: 20 }}>Option Image</h6>

        <input
          onChange={onChange}
          style={{ marginBottom: 10 }}
          type="file"
          name="image"
          id="image-option-field"
          accept={"image/*"}
          placeholder="new option image"
        />
        {newOption.image && (
          <img
            className={"option-image"}
            src={newOption.image.file}
            alt="target"
          />
        )}
      </div>
    </Fragment >
  );
};

export default ImageOptions;
