import { API } from "aws-amplify";

export const getCompanyJson = (companyId, botId) => {
  return new Promise((res, rej) => {
    API.get("dashboardapi", `/v1/company/${companyId}/bot/${botId}`, {
      headers: {
        "Company-Id": companyId
      }
    })
      .then((response) => res(response))
      .catch((error) => rej(error));
  });
};
